import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { jwtDecode } from "jwt-decode";

const RoleContext = React.createContext();

export const RoleProvider = ({ children }) => {
    const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
    const [hasAdminRole, setHasAdminRole] = React.useState(false);
    const [hasSuperAdminRole, setHasSuperAdminRole] = React.useState(false);
    const [isRoleChecked, setIsRoleChecked] = React.useState(false);

    React.useEffect(() => {
        const checkAdminRole = async () => {
            if (isAuthenticated) {
                try {
                    const token = await getAccessTokenSilently();
                    const decodedToken = jwtDecode(token);
                    const userRoles = decodedToken.user_role || [];

                    if (userRoles.includes("admin") || userRoles.includes("product_admin")) {
                        setHasAdminRole(true);
                    }
                    if (userRoles.includes("super_admin")) {
                        setHasSuperAdminRole(true);
                        setHasAdminRole(true);
                    }
                    setIsRoleChecked(true);
                } catch (error) {
                    console.error("Error Decoding token:", error);
                    setIsRoleChecked(true);
                }
            } else {
                setIsRoleChecked(true);
            }
        };

        checkAdminRole();
    }, [isAuthenticated, getAccessTokenSilently]);

    const value = React.useMemo(() => ({
        hasAdminRole,
        hasSuperAdminRole,
        isRoleChecked,
        isLoading,
        isAuthenticated
    }), [hasAdminRole, hasSuperAdminRole, isRoleChecked, isLoading, isAuthenticated]);

    return (
        <RoleContext.Provider value={value}>
            {children}
        </RoleContext.Provider>
    );
};

export const useRole = () => React.useContext(RoleContext);

export const RequireAdminRole = ({ children }) => {
    const { hasAdminRole, isRoleChecked, isLoading, isAuthenticated } = useRole();

    if (isLoading || !isRoleChecked) {
        return <CircularProgress />;
    }

    if (!isAuthenticated || !hasAdminRole) {
        return <Navigate to="/dashboard" replace />;
    }

    return children; 
};

export const RequireSuperAdminRole = ({ children }) => {
    const { hasSuperAdminRole, isLoading, isRoleChecked, isAuthenticated} = useRole();

    if (isLoading || !isRoleChecked) {
        return <CircularProgress />;
    }

    if (!isAuthenticated || !hasSuperAdminRole) {
        return <Navigate to="/dashboard" replace />;
    }

    return children;

}