import React, { useMemo, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Paper,
} from '@mui/material';

const UploadHistoryEmailModal = ({ 
  open, 
  onClose, 
  selectedUser, 
  onSend,
  sending,
  transitionDuration,
  slotProps
}) => {
  const generateEmailContent = useMemo(() => (userName) => {
    const lastMonth = new Date(new Date().setMonth(new Date().getMonth() - 1))
      .toLocaleString('default', { month: 'long', year: 'numeric' });
    const currentMonth = new Date().toLocaleString('default', { month: 'long' });

    return `Hello ${userName},

This is your monthly reminder to please request velocity reports for the month of ${lastMonth} and upload them into the FoodTrails platform.

As mentioned before, we are currently building data pipelines for Farm Fresh RI, Roch's, Gordon Food Service, and Sysco only. Please request reports from the suppliers on that list that you work with. Reports from other suppliers are not needed at this time.

If you experience any issues, please let us know what the issue was, what you were doing at the time the issue occurred, as well as any screenshots if possible.

Summary of your "to do's" this month:
• Request velocity reports for ${lastMonth} purchases from Farm Fresh RI, Roch's, Gordon Food Service, and Sysco (only those you work with).
• Login into FoodTrails and upload velocity reports
• Submit feedback on your experiences via the FoodTrails site, including letting us know if you have trouble logging in, if the schools assigned to you are incorrect, or if you have trouble uploading velocity reports
• Schedule and confirm your monthly user interview for ${currentMonth}

We greatly appreciate your time, feedback, and energy spent being part of this initial group of users!

Thank you, and talk soon,
The FoodTrails Team`;
  }, []);

  const convertToHtml = useCallback((content) => {
    const paragraphs = content.split('\n\n');
    let htmlContent = '';
    
    for (const paragraph of paragraphs) {
      if (paragraph.startsWith('Summary of your "to do\'s" this month:')) {
        const [title, ...items] = paragraph.split('\n');
        htmlContent += `<p><strong>${title}</strong></p>\n`;
        htmlContent += '<ul>\n';
        for (const item of items) {
          htmlContent += `  <li>${item.substring(2)}</li>\n`;
        }
        htmlContent += '</ul>\n';
      } else {
        htmlContent += `<p>${paragraph}</p>\n`;
      }
    }

    return `<html>
<head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
</head>
<body style="font-family: Arial, sans-serif; line-height: 1.6;">
${htmlContent}
</body>
</html>`;
  }, []);

  const emailContent = useMemo(() => 
    selectedUser ? generateEmailContent(selectedUser.userName) : '',
    [selectedUser, generateEmailContent]
  );

  const handleSend = () => {
    onSend({
      subject: 'Monthly Upload Reminder',
      content: convertToHtml(emailContent)
    });
  };

  const previewHtml = useMemo(() => {
    return convertToHtml(emailContent);
  }, [emailContent, convertToHtml]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="reminder-confirmation-dialog"
      maxWidth="md"
      fullWidth
      transitionDuration={transitionDuration}
      slotProps={slotProps}
      PaperProps={{
        sx: {
          m: { xs: 1, sm: 2 },
          maxHeight: 'calc(100% - 32px)'
        }
      }}
    >
      <DialogTitle id="reminder-confirmation-dialog" sx={{ pb: 1 }}>
        Send Upload Reminder
      </DialogTitle>
      <Box sx={{ px: 3, pb: 2 }}>
        {selectedUser && (
          <Box>
            <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
              Sending reminder to: {selectedUser.userName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Email: {selectedUser.userEmail}
            </Typography>
          </Box>
        )}
      </Box>
      <DialogContent>
        <Paper 
          variant="outlined" 
          sx={{ 
            p: 3,
            height: '500px',
            overflow: 'auto',
            bgcolor: '#ffffff',
            '& p': { mb: 2 },
            '& ul': { mb: 2, pl: 3 }
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: previewHtml }} />
        </Paper>
      </DialogContent>
      <DialogActions sx={{ 
        justifyContent: 'center',
        pb: 3,
        gap: 2
      }}>
        <Button 
          onClick={onClose} 
          color="primary"
          variant="outlined"
          sx={{
            minWidth: 100,
            borderRadius: 1,
            textTransform: 'none',
            fontWeight: 500,
            boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
            '&:hover': {
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSend}
          color="primary"
          variant="contained"
          disabled={!selectedUser || sending}
          sx={{
            minWidth: 100,
            borderRadius: 1,
            textTransform: 'none',
            fontWeight: 500,
            boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
            '&:hover': {
              boxShadow: '0 2px 4px rgba(0,0,0,0.15)'
            }
          }}
        >
          {sending ? 'Sending...' : 'Send Reminder'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(UploadHistoryEmailModal);
