import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

export default function Footer() {
  const [open, setOpen] = useState(false);
  const currentYear = new Date().getFullYear();
  const version = process.env.REACT_APP_GITHUB_SHA || 'development';

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppBar position="static" color="transparent" elevation={1} style={{ marginTop: 'auto', alignItems: "center", bottom: 0 }}>
        <Toolbar>
          <Typography variant="body2">
            © {currentYear} FoodTrails Inc. All rights reserved.{' '}
            <a 
              href="#" 
              onClick={(e) => {
                e.preventDefault();
                handleClickOpen();
              }}
            >
              Terms and Conditions
            </a>
          </Typography>
        </Toolbar>
      </AppBar>
      
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Terms and Conditions</DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Geolocation and Geocoding data is provided via the <a href="https://developers.google.com/maps/documentation/geocoding/overview" target="_blank">Google Maps API</a> and its use is subject to Google's <a href="https://policies.google.com/terms?hl=en" target="_blank">Terms of Service</a> and <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a>.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Typography variant="body2" color="textSecondary" sx={{ mr: 'auto', opacity: 0.5, pl: 2 }}>
            Site Version:{version.slice(0, 7)}
          </Typography>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
