export const formatCurrency = (value) => {
    if (value === null || value === undefined || isNaN(value)) {
      return '$[ERR]'
    }
  
    if (Math.abs(value) >= 1000000000) {
      const billions = value / 1000000000;
      return `$${billions.toFixed(1)}B`;
    } else if (Math.abs(value) >= 1000000) {
      const millions = value / 1000000;
      return `$${millions.toFixed(1)}M`;
    } else if (Math.abs(value) >= 1000) {
      const thousands = value / 1000;
      return `$${thousands.toFixed(0)}k`;
    } else {
      return `$${value.toFixed(0)}`;
    }
  };