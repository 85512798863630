import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Button,
  Chip,
  Paper,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Typography,
  Tooltip,
  TableSortLabel,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { useAuth0 } from "@auth0/auth0-react";
import { fetchUploads, downloadUpload, triggerPipeline, destroyAssociatedData, deleteUploadById } from "../../utils/api/uploadsApi";
import { useSnackbar } from "../BaseComponents/GlobalErrorSnackbar";
import SearchInputWithDates from "../BaseComponents/SearchInputWithDates";
import advancedFormat from "dayjs/plugin/advancedFormat";
import {
  Autorenew as AutorenewIcon,
  Delete as DeleteIcon,
  DeleteSweep as DeleteSweepIcon,
  ErrorOutline as ErrorOutlineIcon,
  HelpOutlineOutlined as HelpOutlineOutlinedIcon,
  Refresh as RefreshIcon,
  TaskAlt as TaskAltIcon,
} from '@mui/icons-material';
import { useRole } from "../../components/RoleProvider";

const STATUS_CONFIGS = {
  success: {
    icon: TaskAltIcon,
    label: 'Success',
    color: 'success',
    tooltip: 'Document Successfully Processed'
  },
  failed: {
    icon: ErrorOutlineIcon,
    label: 'Failed',
    color: 'error',
    tooltip: 'Document Processing Failed'
  },
  processing: {
    icon: AutorenewIcon,
    label: 'Processing',
    color: 'warning',
    tooltip: 'Currently Processing Document'
  },
  unknown: {
    icon: HelpOutlineOutlinedIcon,
    label: 'Unknown',
    color: 'warning',
    tooltip: 'Unknown Processing Status'
  }
};

const POLLING_INTERVAL = 5000; // 5 seconds

dayjs.extend(advancedFormat);
dayjs.extend(utc);

const PastUploads = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchParams, setSearchParams] = useState({
    searchTerm: "",
    startDate: dayjs().utc().subtract(1, "month").startOf('day').format("YYYY-MM-DD"),
    endDate: dayjs().utc().endOf('day').format("YYYY-MM-DD")
  });
  const [sortDirection, setSortDirection] = useState('desc');
  const { getAccessTokenSilently } = useAuth0();
  const { handleSuccess, handleError } = useSnackbar();
  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'desc' });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [openRerunModal, setOpenRerunModal] = useState(false);
  const [openDeletePurchasesModal, setOpenDeletePurchasesModal] = useState(false);
  const [openDeleteDocumentModal, setOpenDeleteDocumentModal] = useState(false);
  const { hasAdminRole } = useRole();

  const formatFileData = useCallback((file) => ({
    ...file,
    created_at: dayjs(file.created_at).format("MMMM DD, YYYY h:mm A"),
    created_at_raw: file.created_at,
    status_config: STATUS_CONFIGS[file.processing_status]
  }), []);

  const fetchFiles = useCallback(
    async (searchTerm, startDate, endDate) => {
      try {
        const token = await getAccessTokenSilently();

        // Dates are stored in UTC in the database, but displayed in local time to the user. 
        // This allows them to search using their local time
        const utcStartDate = dayjs(startDate).utc().startOf('day').format("YYYY-MM-DD");
        const utcEndDate = dayjs(endDate).utc().endOf('day').format("YYYY-MM-DD");

        const data = await fetchUploads(token, searchTerm, utcStartDate, utcEndDate);
        const formattedData = data.map(formatFileData);
        setFiles(formattedData);
      } catch (error) {
        setError(error)
        handleError(error);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently, formatFileData]
  );

  const updateFileStatuses = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const data = await fetchUploads(
        token,
        searchParams.searchTerm,
        searchParams.startDate,
        searchParams.endDate
      );

      setFiles(prevFiles => {
        const existingFileIds = new Set(prevFiles.map(f => f.id));
        const updatedFiles = [...prevFiles];

        data.forEach(newFile => {
          if (!existingFileIds.has(newFile.id)) {
            updatedFiles.push(formatFileData(newFile));
          } else {
            const index = updatedFiles.findIndex(f => f.id === newFile.id);
            if (index !== -1 && updatedFiles[index].processing_status !== newFile.processing_status) {
              updatedFiles[index] = formatFileData(newFile);
            }
          }
        });
        return updatedFiles;
      });
    } catch (error) {
      handleError(error);
    }
  }, [getAccessTokenSilently, searchParams, formatFileData]);

  const handleDownload = useCallback(async (fileId) => {
    try {
      const token = await getAccessTokenSilently();
      const downloadUrl = await downloadUpload(fileId, token);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = downloadUrl;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      handleError(error);
    }
  }, [getAccessTokenSilently, handleError]);

  const requestSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const getSortDirection = (key) => {
    return sortConfig.key === key ? sortConfig.direction : 'asc';
  };

  const sortedFiles = useMemo(() => {
    let sortableFiles = [...files];
    if (sortConfig.key !== null) {
      sortableFiles.sort((a, b) => {
        if (sortConfig.key === 'created_at') {
          return (dayjs(a.created_at_raw).isBefore(dayjs(b.created_at_raw)) ? -1 : 1) * (sortConfig.direction === 'asc' ? 1 : -1);
        } else {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
          }
          return 0;
        }
      });
    }
    return sortableFiles;
  }, [files, sortConfig]);

  useEffect(() => {
    fetchFiles(
      searchParams.searchTerm,
      searchParams.startDate,
      searchParams.endDate
    );
  }, [fetchFiles]);

  // Polling for status updates
  useEffect(() => {
    // Only poll if there are files in 'processing' state'
    const shouldPoll = files.some(uploadFile => 
      uploadFile.processing_status === 'processing'
    );

    if (!shouldPoll) {
      return;
    }
    console.log('POLLING')
    const intervalId = setInterval(updateFileStatuses, POLLING_INTERVAL);

    return () => clearInterval(intervalId);
  }, [files, updateFileStatuses]);

  const closeRerunModal = () => {
    setOpenRerunModal(false);
    setSelectedFile(null);
  };

  const closeDeletePurchasesModal = () => {
    setOpenDeletePurchasesModal(false);
    setSelectedFile(null);
  };

  const closeDeleteDocumentModal = () => {
    setOpenDeleteDocumentModal(false);
    setSelectedFile(null);
  };

  const handleMenuOpen = (event, file) => {
    setAnchorEl(event.currentTarget);
    setSelectedFile(file);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRerunPipeline = () => {
    setOpenRerunModal(true);
    handleMenuClose();
  };

  const handleDeletePurchases = () => {
    setOpenDeletePurchasesModal(true);
    handleMenuClose();
  };

  const handleDeleteDocument = () => {
    setOpenDeleteDocumentModal(true);
    handleMenuClose();
  };

  const handleConfirmRerun = async () => {
    try {
      const token = await getAccessTokenSilently();
      const result = await triggerPipeline(selectedFile.id, token);
      handleSuccess(result.message);
      setOpenRerunModal(false);
    } catch (error) {
      handleError(error);
      setOpenRerunModal(false);
    }
  };

  const handleConfirmDeletePurchases = async () => {
    try {
      const token = await getAccessTokenSilently();
      const result = await destroyAssociatedData(selectedFile.id, token);
      handleSuccess(result.message);
      setOpenDeletePurchasesModal(false);
    } catch (error) {
      handleError(error);
      setOpenDeletePurchasesModal(false);
    }
  };

  const handleConfirmDeleteDocument = async () => {
    try {
      const token = await getAccessTokenSilently();
      const result = await deleteUploadById(selectedFile.id, token);
      handleSuccess(result.message);
      setOpenDeleteDocumentModal(false);
      // Refresh the uploads list
      fetchFiles();
    } catch (error) {
      handleError(error);
      setOpenDeleteDocumentModal(false);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="h6" color="error">
          Error loading uploads.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" sx={{ mb: 4, fontWeight: "bold" }}>
        Past Uploads
      </Typography>
      <SearchInputWithDates
        onSearch={fetchFiles}
        placeholder="Search by file name"
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" fontWeight="bold">
                  Status
                </Typography>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'original_filename'}
                  direction={getSortDirection('original_filename')}
                  onClick={() => requestSort('original_filename')}
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    File Name
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'created_at'}
                  direction={getSortDirection('created_at')}
                  onClick={() => requestSort('created_at')}
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    Date Uploaded
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'distributor'}
                  direction={getSortDirection('distributor')}
                  onClick={() => requestSort('distributor')}
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    Distributor
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" fontWeight="bold">
                  Download
                </Typography>
              </TableCell>
              {hasAdminRole && (
                <TableCell />
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedFiles.map((file) => (
              <TableRow
                key={file.id}
                sx={{
                  "&:nth-of-type(odd)": { backgroundColor: "action.hover" },
                }}
              >
                <TableCell>
                  <Tooltip title={file.status_config.tooltip} arrow>
                    <Chip
                      icon={<file.status_config.icon fontSize="small" />}
                      label={file.status_config.label}
                      color={file.status_config.color}
                      size="small"
                      sx={{
                        '& .MuiChip-icon': {
                          marginLeft: '8px',
                        }
                      }}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={file.s3_url || ''} arrow>
                    <span>{file.original_filename || 'N/A'}</span>
                  </Tooltip>
                </TableCell>
                <TableCell>{file.created_at || ''}</TableCell>
                <TableCell>{file.distributor || 'Unknown'}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<DownloadIcon />}
                    onClick={() => handleDownload(file.id)}
                  >
                    Download
                  </Button>
                </TableCell>
                {hasAdminRole && (
                  <TableCell>
                    <IconButton
                      aria-label="admin options"
                      onClick={(e) => handleMenuOpen(e, file)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {hasAdminRole && (
        <>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem 
              onClick={handleRerunPipeline}
              sx={{ 
                color: 'primary.main',
                '&:hover': { 
                  backgroundColor: 'primary.main',
                  color: 'white'
                }
              }}
            >
              <RefreshIcon sx={{ mr: 1 }} />
              Re-run Document Pipeline
            </MenuItem>
            <MenuItem 
              onClick={handleDeletePurchases}
              sx={{ 
                color: 'warning.main',
                '&:hover': { 
                  backgroundColor: 'warning.main',
                  color: 'white'
                }
              }}
            >
              <DeleteSweepIcon sx={{ mr: 1 }} />
              Delete Associated Purchases and Products
            </MenuItem>
            <MenuItem 
              onClick={handleDeleteDocument}
              sx={{ 
                color: 'error.main',
                '&:hover': { 
                  backgroundColor: 'error.main',
                  color: 'white'
                }
              }}
            >
              <DeleteIcon sx={{ mr: 1 }} />
              Delete Purchase Document
            </MenuItem>
          </Menu>

          {/* Rerun Pipeline Modal */}
          <Dialog
            open={openRerunModal}
            onClose={closeRerunModal}
          >
            <DialogTitle sx={{ color: 'primary.main', display: 'flex', alignItems: 'center', gap: 1 }}>
              <RefreshIcon /> Confirm Re-run Pipeline
            </DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to re-run the document pipeline for:
                <Typography component="div" sx={{ mt: 1, fontWeight: 'bold' }}>
                  {selectedFile?.original_filename || ''}
                </Typography>
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeRerunModal}>Cancel</Button>
              <Button 
                onClick={handleConfirmRerun}
                variant="contained" 
                color="primary"
                startIcon={<RefreshIcon />}
              >
                Re-run Pipeline
              </Button>
            </DialogActions>
          </Dialog>

          {/* Delete Purchases Modal */}
          <Dialog
            open={openDeletePurchasesModal}
            onClose={closeDeletePurchasesModal}
          >
            <DialogTitle sx={{ color: 'warning.main', display: 'flex', alignItems: 'center', gap: 1 }}>
              <DeleteSweepIcon /> Confirm Delete Associated Data
            </DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to delete all purchases and products associated with:
                <Typography component="div" sx={{ mt: 1, fontWeight: 'bold' }}>
                  {selectedFile?.original_filename || ''}
                </Typography>
                <Typography color="warning.main" sx={{ mt: 2 }}>
                  This action cannot be undone.
                </Typography>
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDeletePurchasesModal}>Cancel</Button>
              <Button 
                onClick={handleConfirmDeletePurchases}
                variant="contained" 
                color="warning"
                startIcon={<DeleteSweepIcon />}
              >
                Delete Associated Data
              </Button>
            </DialogActions>
          </Dialog>

          {/* Delete Document Modal */}
          <Dialog
            open={openDeleteDocumentModal}
            onClose={closeDeleteDocumentModal}
          >
            <DialogTitle sx={{ color: 'error.main', display: 'flex', alignItems: 'center', gap: 1 }}>
              <DeleteIcon /> Confirm Delete Document
            </DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to delete this purchase document:
                <Typography component="div" sx={{ mt: 1, fontWeight: 'bold' }}>
                  {selectedFile?.original_filename || ''}
                </Typography>
                <Typography color="error.main" sx={{ mt: 2 }}>
                  This action cannot be undone.
                </Typography>
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDeleteDocumentModal}>Cancel</Button>
              <Button 
                onClick={handleConfirmDeleteDocument}
                variant="contained" 
                color="error"
                startIcon={<DeleteIcon />}
              >
                Delete Document
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Container>
  );
};

export default PastUploads;
