import React, { useState, useEffect, useCallback, useRef, memo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Chip,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "../BaseComponents/GlobalErrorSnackbar";
import { fetchUsers, searchUsers } from "../../utils/api/usersApi";
import { fetchUploads } from "../../utils/api/uploadsApi";
import { sendEmail } from "../../utils/api/emailApi";
import SearchInput from "../BaseComponents/SearchInput";
import UserPermissionManagement from "./UserPermissionManagement";
import UserInvite from "./UserInvite";
import UploadHistoryEmailModal from './UploadHistoryEmailModal';
import { useFacilities } from "../../context/FacilitiesContext";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { alpha } from '@mui/material/styles';
import {
  TaskAlt as TaskAltIcon,
  ErrorOutline as ErrorOutlineIcon,
  Autorenew as AutorenewIcon,
  HelpOutlineOutlined as HelpOutlineOutlinedIcon,
} from '@mui/icons-material';

// Memoize the modals to prevent unnecessary re-renders
const MemoizedUserPermissionManagement = memo(UserPermissionManagement);
const MemoizedUserInvite = memo(UserInvite);
const MemoizedUploadHistoryEmailModal = memo(UploadHistoryEmailModal);

const STATUS_CONFIGS = {
  success: {
    icon: TaskAltIcon,
    label: 'Success',
    color: 'success',
    tooltip: 'Document Successfully Processed'
  },
  failed: {
    icon: ErrorOutlineIcon,
    label: 'Failed',
    color: 'error',
    tooltip: 'Document Processing Failed'
  },
  processing: {
    icon: AutorenewIcon,
    label: 'Processing',
    color: 'warning',
    tooltip: 'Currently Processing Document'
  },
  unknown: {
    icon: HelpOutlineOutlinedIcon,
    label: 'Unknown',
    color: 'warning',
    tooltip: 'Unknown Processing Status'
  }
};

function UserManagementContent() {
  const [users, setUsers] = useState([]);
  const [uploadsMap, setUploadsMap] = useState({});
  const [loadingUploads, setLoadingUploads] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedReminderUser, setSelectedReminderUser] = useState(null);
  const [sendingReminder, setSendingReminder] = useState({});
  const { getAccessTokenSilently, user: currentUser } = useAuth0();
  const { handleError, handleSuccess } = useSnackbar();
  const { facilities } = useFacilities();
  const fetchInProgressRef = useRef(false);

  const fetchData = useCallback(async (query = "") => {
    if (fetchInProgressRef.current) return;
    fetchInProgressRef.current = true;

    try {
      const token = await getAccessTokenSilently();
      let usersData;
      
      if (query.trim()) {
        // Filter users locally if we already have them
        if (users.length > 0) {
          const searchTerm = query.toLowerCase();
          usersData = users.filter(user => 
            user.first_name?.toLowerCase().includes(searchTerm) ||
            user.last_name?.toLowerCase().includes(searchTerm) ||
            user.email?.toLowerCase().includes(searchTerm)
          );
        } else {
          // Only fetch from API if we don't have users yet
          usersData = await fetchUsers(token);
        }
      } else {
        usersData = await fetchUsers(token);
      }
      
      setUsers(usersData);
    } catch (error) {
      setError(error);
      handleError(error);
    } finally {
      setLoading(false);
      fetchInProgressRef.current = false;
    }
  }, [getAccessTokenSilently, handleError, users]);

  const processUploads = useCallback((uploads, userId) => {
    const now = new Date();
    const twoMonthsAgo = new Date(now.getFullYear(), now.getMonth() - 2, 1);
    
    const lastThreeMonths = Array.from({ length: 3 }, (_, i) => {
      const date = new Date(now.getFullYear(), now.getMonth() - i, 1);
      return date.toISOString().slice(0, 7);
    });

    const userUploads = uploads.filter(upload => 
      upload.uploaded_by.id === userId && 
      new Date(upload.created_at) >= twoMonthsAgo
    );

    const monthlyUploads = Object.fromEntries(lastThreeMonths.map(month => [month, 0]));
    const uploadsByMonth = {};

    userUploads.forEach(upload => {
      const monthKey = upload.created_at.slice(0, 7);
      if (monthlyUploads.hasOwnProperty(monthKey)) {
        monthlyUploads[monthKey]++;
      }
      if (!uploadsByMonth[monthKey]) {
        uploadsByMonth[monthKey] = [];
      }
      uploadsByMonth[monthKey].push(upload);
    });

    return {
      monthlyUploads,
      uploadsByMonth
    };
  }, []);

  const fetchUserUploads = useCallback(async (userId) => {
    if (uploadsMap[userId] || loadingUploads[userId]) return;
    
    setLoadingUploads(prev => ({ ...prev, [userId]: true }));
    
    try {
      const token = await getAccessTokenSilently();
      const uploadsData = await fetchUploads(token);
      const processedUploads = processUploads(uploadsData, userId);
      
      setUploadsMap(prev => ({
        ...prev,
        [userId]: processedUploads
      }));
    } catch (error) {
      handleError(error);
    } finally {
      setLoadingUploads(prev => ({ ...prev, [userId]: false }));
    }
  }, [getAccessTokenSilently, handleError, processUploads, uploadsMap, loadingUploads]);

  const handleAccordionChange = useCallback((userId) => (event, expanded) => {
    if (expanded) {
      fetchUserUploads(userId);
    }
  }, [fetchUserUploads]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearch = useCallback((query) => {
    setSearchQuery(query);
    fetchData(query);
  }, [fetchData]);

  const getMonthName = (monthKey) => {
    const date = new Date(monthKey + '-01T00:00:00');
    return date.toLocaleDateString(undefined, { month: 'long' });
  };

  const getChipStyle = (month) => {
    const styles = {
      current: { bg: '#1976d2', color: '#ffffff' },
      previous: { bg: '#2e7d32', color: '#ffffff' },
      oldest: { bg: '#1976d2', color: '#ffffff' }
    };
    
    const now = new Date();
    const currentMonth = now.toISOString().slice(0, 7);
    const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1).toISOString().slice(0, 7);
    
    const style = month === currentMonth ? styles.current 
                 : month === lastMonth ? styles.previous 
                 : styles.oldest;
                 
    return {
      backgroundColor: style.bg,
      color: style.color,
      fontWeight: 500,
      minWidth: 130,
      borderRadius: 20,
      '& .MuiChip-label': {
        px: 2
      }
    };
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'success':
        return 'success';
      case 'failed':
        return 'error';
      case 'unknown':
        return 'warning';
      default:
        return 'default';
    }
  };

  const openConfirmationModal = (userId, userName, userEmail) => {
    setSelectedReminderUser({ userId, userName, userEmail });
    setModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setModalOpen(false);
    setSelectedReminderUser(null);
  };

  const handleSendReminder = async (emailData) => {
    if (!selectedReminderUser) return;
    
    try {
      setSendingReminder(prev => ({ ...prev, [selectedReminderUser.userId]: true }));
      const token = await getAccessTokenSilently();
      
      await sendEmail({
        ...emailData,
        to: selectedReminderUser.userEmail,
      }, token);
      
      handleSuccess(`Reminder sent to ${selectedReminderUser.userName}`);
      closeConfirmationModal();
    } catch (error) {
      handleError(error);
    } finally {
      setSendingReminder(prev => ({ ...prev, [selectedReminderUser.userId]: false }));
    }
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Typography color="error">Error loading users: {error.message}</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 3 
      }}>
        <Typography variant="h4" sx={{ fontWeight: 400 }}>
          User Management
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsInviteModalOpen(true)}
          sx={{
            borderRadius: 1,
            textTransform: 'none',
            py: 1,
            px: 2,
            fontWeight: 500,
            boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
            '&:hover': {
              backgroundColor: 'primary.dark',
              boxShadow: '0 2px 4px rgba(0,0,0,0.15)'
            }
          }}
        >
          Invite Users
        </Button>
      </Box>

      <SearchInput
        value={searchQuery}
        onChange={setSearchQuery}
        onSearch={handleSearch}
        placeholder="Search users..."
      />

      <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {users.map((user) => (
          <Accordion 
            key={user.id}
            onChange={handleAccordionChange(user.id)}
            sx={{ 
              bgcolor: 'background.paper',
              boxShadow: 1,
              borderRadius: 2,
              '&:before': { display: 'none' },
              '& .MuiAccordionSummary-root': {
                minHeight: 60,
                borderBottom: '1px solid',
                borderColor: 'divider',
                px: { xs: 2, sm: 2 },
                py: 0.5,
                '&.Mui-expanded': {
                  bgcolor: alpha('#1976d2', 0.03),
                  minHeight: 60
                }
              }
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box sx={{ 
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 1
              }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.25 }}>
                  <Typography variant="h6" sx={{ 
                    fontWeight: 400,
                    fontSize: '1rem',
                    lineHeight: 1.2
                  }}>
                    {`${user.first_name} ${user.last_name}`}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.875rem' }}>
                    {user.email}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', gap: 2 }}>
                  {currentUser?.email !== 'caitlin.mandel@ride.ri.gov' && (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        openConfirmationModal(
                          user.id,
                          `${user.first_name} ${user.last_name}`,
                          user.email
                        );
                      }}
                      disabled={sendingReminder[user.id]}
                      startIcon={sendingReminder[user.id] ? 
                        <CircularProgress size={16} /> : 
                        <NotificationsIcon sx={{ fontSize: 16 }} />
                      }
                      sx={{
                        textTransform: 'none',
                        borderRadius: 1,
                        color: 'primary.main',
                        borderColor: 'primary.main',
                        py: 0.5,
                        px: 2,
                        height: 32,
                        minWidth: 140,
                        fontSize: '0.875rem',
                        fontWeight: 500,
                        boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
                        '&:hover': {
                          borderColor: 'primary.dark',
                          backgroundColor: alpha('#1976d2', 0.04),
                          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                        },
                        '&:disabled': {
                          borderColor: 'grey.300',
                          boxShadow: 'none'
                        }
                      }}
                    >
                      Reminder
                    </Button>
                  )}

                  <Button
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedUser(user);
                    }}
                    variant="contained"
                    color="primary"
                    sx={{ 
                      borderRadius: 1,
                      textTransform: 'none',
                      py: 0.5,
                      px: 2,
                      height: 32,
                      minWidth: 140,
                      fontSize: '0.875rem',
                      fontWeight: 500,
                      boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
                      '&:hover': {
                        backgroundColor: 'primary.dark',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.15)'
                      }
                    }}
                  >
                    Manage Facilities
                  </Button>
                </Box>
              </Box>
            </AccordionSummary>

            <AccordionDetails sx={{ px: { xs: 2, sm: 2 }, py: 1.5 }}>
              {loadingUploads[user.id] ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
                  <CircularProgress size={24} />
                </Box>
              ) : uploadsMap[user.id] ? (
                <>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" sx={{ 
                      fontWeight: 400,
                      mb: 1,
                      fontSize: '1rem'
                    }}>
                      Upload History
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                      {Object.entries(uploadsMap[user.id].monthlyUploads)
                        .sort(([a], [b]) => b.localeCompare(a))
                        .map(([month, count]) => (
                          <Chip 
                            key={month}
                            label={`${getMonthName(month)}: ${count}`}
                            sx={{
                              ...getChipStyle(month),
                              height: 24,
                              '& .MuiChip-label': {
                                px: 1.5,
                                fontSize: '0.8125rem'
                              }
                            }}
                          />
                        ))}
                    </Box>
                  </Box>

                  {Object.entries(uploadsMap[user.id].uploadsByMonth)
                    .sort(([a], [b]) => b.localeCompare(a))
                    .map(([month, monthUploads]) => (
                      <Box key={month} sx={{ mb: 2 }}>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          mb: 1
                        }}>
                          <Typography variant="subtitle1" sx={{ 
                            fontWeight: 400,
                            fontSize: '0.9375rem'
                          }}>
                            {getMonthName(month)}
                          </Typography>
                          <Chip 
                            label={`${monthUploads.length} uploads`}
                            size="small"
                            sx={{ 
                              bgcolor: alpha('#1976d2', 0.1),
                              color: 'primary.main',
                              fontWeight: 400,
                              height: 20,
                              '& .MuiChip-label': {
                                px: 1,
                                fontSize: '0.75rem'
                              }
                            }}
                          />
                        </Box>
                        <TableContainer 
                          component={Paper} 
                          variant="outlined"
                          sx={{
                            borderRadius: 1,
                            overflow: 'hidden',
                            '& .MuiTable-root': {
                              minWidth: { xs: 600, sm: 800 }
                            },
                            '& .MuiTableCell-root': {
                              py: 0.75,
                              px: 1.5,
                              fontSize: '0.875rem'
                            },
                            '& .MuiTableHead-root': {
                              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.03),
                              '& .MuiTableCell-root': {
                                py: 0.75
                              }
                            }
                          }}
                        >
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ fontWeight: 400 }}>File Name</TableCell>
                                <TableCell sx={{ fontWeight: 400 }}>Document Type</TableCell>
                                <TableCell sx={{ fontWeight: 400 }}>Distributor</TableCell>
                                <TableCell sx={{ fontWeight: 400 }}>Upload Date</TableCell>
                                <TableCell sx={{ fontWeight: 400 }}>Status</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {monthUploads.map((upload) => (
                                <TableRow key={upload.id}>
                                  <TableCell>{upload.original_filename}</TableCell>
                                  <TableCell>
                                    {upload.document_type?.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                                  </TableCell>
                                  <TableCell>{upload.distributor}</TableCell>
                                  <TableCell>
                                    {new Date(upload.created_at).toLocaleDateString()}
                                  </TableCell>
                                  <TableCell>
                                    <Tooltip title={STATUS_CONFIGS[upload.processing_status]?.tooltip || 'Unknown Status'} arrow>
                                      <Chip 
                                        icon={STATUS_CONFIGS[upload.processing_status]?.icon && 
                                          React.createElement(STATUS_CONFIGS[upload.processing_status].icon, { 
                                            fontSize: "small" 
                                          })
                                        }
                                        label={STATUS_CONFIGS[upload.processing_status]?.label || 'Unknown'}
                                        color={STATUS_CONFIGS[upload.processing_status]?.color || 'default'}
                                        size="small"
                                        sx={{
                                          '& .MuiChip-icon': {
                                            marginLeft: '8px'
                                          }
                                        }}
                                      />
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    ))}
                </>
              ) : (
                <Typography color="text.secondary" sx={{ fontSize: '0.875rem' }}>
                  No upload history found in the last 3 months
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      {selectedUser && (
        <MemoizedUserPermissionManagement
          user={selectedUser}
          facilities={facilities}
          open={!!selectedUser}
          onClose={() => setSelectedUser(null)}
          transitionDuration={{
            enter: 150,
            exit: 100
          }}
          slotProps={{
            backdrop: {
              sx: { backdropFilter: 'blur(2px)' },
              transitionDuration: {
                enter: 150,
                exit: 100
              }
            }
          }}
        />
      )}

      <MemoizedUserInvite 
        open={isInviteModalOpen}
        onClose={() => setIsInviteModalOpen(false)}
        transitionDuration={{
          enter: 150,
          exit: 100
        }}
        slotProps={{
          backdrop: {
            sx: { backdropFilter: 'blur(2px)' },
            transitionDuration: {
              enter: 150,
              exit: 100
            }
          }
        }}
      />

      <MemoizedUploadHistoryEmailModal
        open={modalOpen}
        onClose={closeConfirmationModal}
        selectedUser={selectedReminderUser}
        onSend={handleSendReminder}
        sending={selectedReminderUser ? sendingReminder[selectedReminderUser.userId] : false}
        transitionDuration={{
          enter: 150,
          exit: 100
        }}
        slotProps={{
          backdrop: {
            sx: { backdropFilter: 'blur(2px)' },
            transitionDuration: {
              enter: 150,
              exit: 100
            }
          }
        }}
      />
    </Container>
  );
}

// Memoize the entire component
export default memo(UserManagementContent);