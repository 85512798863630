import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box, 
  Button, 
  Grid, 
  Card, 
  CardContent, 
  CardHeader, 
  Typography, 
  Container, 
  Stepper, 
  Step, 
  StepLabel, 
  StepContent, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Link
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import InsightsIcon from '@mui/icons-material/Insights';
import StorageIcon from '@mui/icons-material/Storage';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EmailIcon from '@mui/icons-material/Email';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        '.Mui-expanded &': {
          '.expandIconWrapper': {
            display: 'none',
          },
          '.collapseIconWrapper': {
            display: 'block',
          },
        },
        '.expandIconWrapper': {
          display: 'block',
        },
        '.collapseIconWrapper': {
          display: 'none',
        },
      }}
    >
      <Box className="expandIconWrapper">
        <AddIcon />
      </Box>
      <Box className="collapseIconWrapper">
        <RemoveIcon />
      </Box>
    </Box>
  );
};

const benefits = [
  { title: "Track Local Food Purchases", content: "Automatically determine food origin as local, regional, not local, or unknown to track progress toward local food purchasing goals", icon: <LocationOnIcon fontSize="large" />, color: "#2196f3" },
  { title: "Enhance Program Accountability", content: "Gain comprehensive insights into real-time trends in food program spending, improving overall accountability", icon: <AccountBalanceIcon fontSize="large" />, color: "#4caf50" },
  { title: "Increase Data Accuracy", content: "Automated data processing ensures high accuracy in reporting and decision-making", icon: <DataUsageIcon fontSize="large" />, color: "#ff9800" },
  { title: "Eliminate Manual Data Entry", content: "Distributors provide the velocity reports (spreadsheets with itemized purchases). The platform does the rest.", icon: <AutorenewIcon fontSize="large" />, color: "#e91e63" },
];

const steps = [
  {
    label: 'Upload Reports',
    description: 'School district managers upload velocity reports provided by their distributors',
    icon: <CloudUploadIcon />,
    color: "#2196f3"
  },
  {
    label: 'Automatic Processing',
    description: 'FoodTrails automatically categorizes purchases, standardizes units, fleshes out food origin information, and determines whether a product is local – based on your definition',
    icon: <AutorenewIcon />,
    color: "#4caf50"
  },
  {
    label: 'Gain Insights',
    description: 'Administrators gain actionable insights into local food purchasing trends and identify opportunities across nutrition programs',
    icon: <InsightsIcon />,
    color: "#ff9800"
  },
  {
    label: 'Build Database',
    description: "FoodTrails builds an aggregate product and supplier database, based on users' verified purchases",
    icon: <StorageIcon />,
    color: "#e91e63"
  }
];

const grantAlignmentPoints = [
  "Improves program accountability across nutrition programs",
  "Enhances data accuracy through automated processing",
  "Boosts program performance with real-time insights on local food spending",
  "Identifies areas for improvement in local food purchasing",
  "Automates data categorization and standardization"
];

const sampleNarrative = `
National research has shown that farm to school programs are proven to support the goals of federally funded child nutrition programs by improving student knowledge related to eating healthy, positively impacting academic performance, and increasing willingness to try and like healthy foods.

While this national research is compelling, many states lack the local level data needed to comprehensively evaluate current local purchasing efforts and barriers to participation.

Over the course of the last few years, state agencies have been working with farm to school Networks to develop key functional requirements and identify data points to be tracked in a local purchasing database. A web-based tracking platform, called FoodTrails, has been developed and is currently being piloted in the state of Rhode Island, to track local procurement metrics for School Food Authorities. FoodTrails will provide reliable and robust data for quantitative analysis of local purchasing, which can be used to support funding requests such as sub-grant opportunities as well as possible local purchasing incentive legislation and will also be useful in evaluating program outcomes and strategies for expansion.

Research that led to the development of FoodTrails determined that manual data entry is not only a barrier to the collection of local purchasing data but also leaves significant room for error. In line with the goals of this grant, expansion and use of the FoodTrails platform would improve efficiency and accuracy of data collection, providing accountability for farm to school efforts and allowing agencies to measure program performance.

Use of this platform will allow local level and state agency staff to save time and money related to data collection and reporting, set a baseline for goal-setting, make data-driven decisions, and will allow for real time reporting of local purchasing data. Additionally, if states follow the example of others around the country and introduce local purchasing incentive legislation to support farm to school efforts, an automated, web-based local purchasing tracking platform, FoodTrails, will increase the integrity of such a program and will provide state agencies the required tools for sufficient monitoring and oversight.`;











export default function Content() {
  const handleRequestInfo = () => {
    window.location.href = "mailto:contact@foodtrails.org?subject=Request%20More%20Information%20About%20FoodTrails";
  };

  return (
    <Box component="main">
      {/* Hero Section */}
      <Box sx={{ 
        bgcolor: '#e3f2fd', // Light blue background
        color: '#0d47a1', // Dark blue text
        py: { xs: 6, md: 10 }, 
        borderBottom: '1px solid #bbdefb', // Subtle border for separation
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'left',
        minHeight: '40vh'
      }}>
        <Container maxWidth="md">
          <Typography 
            variant="h1" 
            component="h1" 
            sx={{ 
              color: 'black',
              fontWeight: 'bold',
              fontSize: { xs: '2.5rem', sm: '3.25rem', md: '4.5rem' },
              lineHeight: 1.1,
              mb: 3
            }}
          >
            FoodTrails:
          </Typography>
          <Typography 
            variant="h2" 
            component="h2" 
            sx={{ 
              fontWeight: 'normal',
              fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2.25rem' },
              lineHeight: 1.3,
              color: '#0d47a1',
              maxWidth: '950px'
            }}
          >
            The automated solution for tracking local food purchases and enhancing accountability in nutrition programs
          </Typography>
        </Container>
      </Box>

      {/* Video section */}
      <Container maxWidth="md" sx={{ mt: 8, mb: 8 }}>
        <Typography 
          variant="h3" 
          gutterBottom 
          align="center" 
          sx={{ 
            color: '#0d47a1', 
            fontWeight: 'bold',
            fontSize: { xs: '1.75rem', sm: '2.25rem', md: '3rem' }
          }}
        >
          See FoodTrails in Action (2 Minutes)
        </Typography>
        <Box sx={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
          <iframe
            src="https://www.youtube.com/embed/FFG2MpqbA8M"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              border: 0,
            }}
            allowFullScreen
            title="FoodTrails Demo Video"
            loading="lazy"
          />
        </Box>
      </Container>

      {/* Benefits Section */}
      <Box sx={{ bgcolor: '#f5f5f5', py: { xs: 4, md: 8 } }}> {/* Light gray background */}
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            align="center" 
            gutterBottom 
            sx={{ 
              color: '#0d47a1', 
              fontWeight: 'bold', 
              mb: 6,
              fontSize: { xs: '1.75rem', sm: '2.25rem', md: '3rem' }
            }}
          >
            Key Benefits
          </Typography>
          <Grid container spacing={4}>
            {benefits.map((benefit, i) => (
              <Grid item key={i} xs={12} sm={6}>
                <Card elevation={2} sx={{ 
                  height: '100%', 
                  display: 'flex', 
                  flexDirection: 'column', 
                  borderRadius: 2,
                  boxShadow: 2
                }}>
                  <CardHeader 
                    avatar={React.cloneElement(benefit.icon, { sx: { color: benefit.color } })}
                    title={benefit.title} 
                    titleTypographyProps={{
                      variant:'h6', 
                      fontWeight: 'bold', 
                      color: '#0d47a1'
                    }}
                    sx={{ pb: 0 }}
                  />
                  <CardContent sx={{ flexGrow: 1, pt: 2 }}>
                    <Typography variant="body2" color="#263238">
                      {benefit.content}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* How it Works section */}
      <Box sx={{ bgcolor: '#ffffff', py: { xs: 4, md: 8 } }}> {/* White background */}
        <Container maxWidth="md">
          <Typography 
            variant="h3" 
            align="center" 
            gutterBottom 
            sx={{ 
              mb: 4, 
              color: '#0d47a1', 
              fontWeight: 'bold',
              fontSize: { xs: '1.75rem', sm: '2.25rem', md: '3rem' }
            }}
          >
            How FoodTrails Works
          </Typography>
          <Stepper orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label} active={true}>
                <StepLabel
                  StepIconComponent={() => React.cloneElement(step.icon, { sx: { color: step.color, fontSize: 30 } })}
                >
                  <Typography variant="h6" color="#0d47a1">{step.label}</Typography>
                </StepLabel>
                <StepContent>
                  <Typography variant="body1" color="#263238" sx={{ ml: 2, mb: 2 }}>
                    {step.description}
                  </Typography>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Container>
      </Box>

      {/* Grant Alignment and Sample Narrative Section */}
      {/* <Box sx={{ bgcolor: '#f5f5f5', py: 8 }}>
        <Container maxWidth="lg">
          <Typography variant="h3" gutterBottom align="center" sx={{ color: '#0d47a1', fontWeight: 'bold', mb: 6 }}>
            Eligible for Federal Grant Funding
          </Typography>
          <Accordion defaultExpanded={false}>
            <AccordionSummary
              expandIcon={<CustomExpandIcon />}
              sx={{
                flexDirection: "row-reverse",
                '& .MuiAccordionSummary-expandIconWrapper': {
                  transform: 'none',
                  marginRight: '12px',
                },
              }}
            >
              <Typography variant="h6">USDA nTIG</Typography>
            </AccordionSummary>
            <Card elevation={3} sx={{ borderRadius: 2, bgcolor: '#ffffff', mb: 4 }}>
              <CardHeader
                title="Applicable Platform Highlights"
                titleTypographyProps={{
                  color: '#0d47a1', 
                  fontWeight: 'bold',
                  variant: 'h6'
                }}
                sx={{ pb: 0 }}
              />
              <CardContent sx={{ pt: 0 }}>
                <List>
                  {grantAlignmentPoints.map((point, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon sx={{ color: '#4caf50' }} />
                      </ListItemIcon>
                      <ListItemText 
                        primary={point} 
                        primaryTypographyProps={{ 
                          color: '#263238', 
                          fontWeight: 'bold',
                          fontSize: '1rem'
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
            <Card elevation={3} sx={{ borderRadius: 2, bgcolor: '#ffffff' }}>
              <CardHeader
                title="Sample Grant Narrative"
                titleTypographyProps={{
                  color: '#0d47a1', 
                  fontWeight: 'bold',
                  variant: 'h6'
                }}
                sx={{ pb: 0 }}
              />
              <CardContent sx={{ pt: 0, px: 4 }}>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    whiteSpace: 'pre-wrap',
                    lineHeight: 1.8,
                    color: '#333',
                    '& p': {
                      marginBottom: '1.5em',
                    },
                    '& p:first-of-type': {
                      marginTop: 0
                    },
                    '& p:last-child': {
                      marginBottom: 0
                    }
                  }}
                >
                  {sampleNarrative.split('\n\n').map((paragraph, index) => (
                    <React.Fragment key={index}>
                      {index === 0 ? (
                        <p>
                          {paragraph}{' '}
                          <Link 
                            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3621303/" 
                            target="_blank" 
                            rel="noopener noreferrer"
                            sx={{ display: 'inline-flex', alignItems: 'center' }}
                          >
                            View Research
                            <OpenInNewIcon sx={{ fontSize: 'small', ml: 0.5 }} />
                          </Link>
                        </p>
                      ) : (
                        <p>{paragraph}</p>
                      )}
                    </React.Fragment>
                  ))}
                </Typography>
              </CardContent>
            </Card>
          </Accordion>
        </Container>
      </Box> */}

      {/* Contact Section */}
      <Box sx={{ bgcolor: '#e3f2fd', py: { xs: 4, md: 8 }, borderTop: '1px solid #bbdefb' }}>
        <Container maxWidth="md">
          <Typography 
            variant="h3" 
            align="center" 
            gutterBottom 
            sx={{ 
              color: '#0d47a1', 
              fontWeight: 'bold', 
              mb: 4,
              fontSize: { xs: '1.75rem', sm: '2.25rem', md: '3rem' }
            }}
          >
            Ready to Get Started?
          </Typography>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            gap: 3
          }}>
            <Button 
              variant="contained" 
              size="large" 
              onClick={handleRequestInfo}
              sx={{ 
                bgcolor: '#1976d2',
                '&:hover': {
                  bgcolor: '#1565c0'
                },
                width: { xs: '100%', sm: 'auto' },
                maxWidth: '300px',
                transition: 'background-color 0.3s ease, transform 0.2s ease',
                '&:hover': {
                  bgcolor: '#1565c0',
                  transform: 'scale(1.05)'
                }
              }}
              aria-label="Request more information about FoodTrails"
            >
              Request More Information
            </Button>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <EmailIcon sx={{ color: '#1976d2', mr: 1 }} />
              <Link 
                href="mailto:contact@foodtrails.org" 
                underline="hover" 
                sx={{ 
                  color: '#1976d2', 
                  fontWeight: 'bold', 
                  fontSize: { xs: '1rem', sm: '1.2rem' },
                  transition: 'color 0.3s ease',
                  '&:hover': {
                    color: '#0d47a1'
                  }
                }}
                aria-label="Send email to contact at foodtrails.org"
              >
                contact@foodtrails.org
              </Link>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}