import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Container, Paper, Typography, Grid, Box, FormControl, InputLabel, Select, Stack } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useAuth0 } from "@auth0/auth0-react"; 
import { fetchAggregateSpendByLocalityOverTime } from '../../utils/api/purchasesApi';
import { useFacilities } from "../../context/FacilitiesContext";
import VirtualizedFacilitySelect from './SubComponents/VirtualizedFacilitySelect';
import { eachMonthOfInterval, format } from 'date-fns';

import DonutChart from "../BaseComponents/DataCards/DonutChart";
import StackedBarChart from "../BaseComponents/DataCards/StackedBarChart";
import Legend from "../BaseComponents/Legend";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { formatCurrency } from "../../utils/formatting";

const DataDisplay = () => {
  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [aggregatedData, setAggregatedData] = useState([]);
  const { facilities } = useFacilities();
  const [selectedFacility, setSelectedFacility] = useState("");
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(() => dayjs().subtract(90, "day"));
  const [endDate, setEndDate] = useState(() => dayjs());
  const [dateRangeError, setDateRangeError] = useState("");

  const flattenedFacilities = useMemo(() => {
    return facilities.reduce((acc, facility) => {
      acc.push({ ...facility, level: 0 });
      if (facility.children) {
        facility.children.forEach(child => {
          acc.push({ ...child, level: 1, parentName: facility.name });
        });
      }
      return acc;
    }, []);
  }, [facilities]);

  useEffect(() => {
    if (flattenedFacilities.length > 0 && !selectedFacility) {
      setSelectedFacility(flattenedFacilities[0].id);
    }
  }, [flattenedFacilities, selectedFacility]);

  const isDateRangeValid = useMemo(() => {
    if (!startDate || !endDate) return false;
    const diffInDays = endDate.diff(startDate, 'day');
    return diffInDays >= 30;
  }, [startDate, endDate]);

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedFacility || !isDateRangeValid) return;

      try {
        setDateRangeError("");
        const accessToken = await getAccessTokenSilently();
        const data = await fetchAggregateSpendByLocalityOverTime(accessToken, startDate, endDate, [selectedFacility], 'full');
        setAggregatedData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (!isDateRangeValid) {
      setDateRangeError("The minimum date range must be at least one month.");
      setAggregatedData([]);
    } else {
      fetchData();
    }
  }, [getAccessTokenSilently, selectedFacility, startDate, endDate, isDateRangeValid]);

  const handleFacilityChange = (facilityId) => {
    setSelectedFacility(facilityId);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const colors = {
    'Local': "#1f77b4",
    'Regional': "#2ca02c",
    'Not Local': "#ff7f0e",
    'Unknown': "#d62728",
  };

  const donutData = useMemo(() => {
    if (aggregatedData.length === 0) return [];
    
    const totals = aggregatedData.reduce((acc, item) => {
      acc.Local += item.local_spend;
      acc.Regional += item.regional_spend;
      acc['Not Local'] += item.not_local_spend;
      acc.Unknown += item.unknown_spend;
      return acc;
    }, { Local: 0, Regional: 0, 'Not Local': 0, Unknown: 0 });

    return Object.entries(totals).map(([category, value]) => ({
      category,
      value: value,
    }));
  }, [aggregatedData]);

  const lineData = useMemo(() => {
    if (aggregatedData.length === 0) return [];

    // Create an array of all months in the selected date range
    const allMonths = eachMonthOfInterval({
      start: startDate.toDate(),
      end: endDate.toDate()
    });

    // Create a map of existing data
    const dataMap = aggregatedData.reduce((acc, item) => {
      acc[item.month] = {
        Local: item.local_spend,
        Regional: item.regional_spend,
        'Not Local': item.not_local_spend,
        Unknown: item.unknown_spend
      };
      return acc;
    }, {});

    // Generate the final array with all months, using 0 for missing data
    return allMonths.map(date => {
      const monthKey = format(date, 'yyyy-MM');
      const monthData = dataMap[monthKey] || {
        Local: 0,
        Regional: 0,
        'Not Local': 0,
        Unknown: 0
      };

      return {
        date: date,
        ...monthData
      };
    });
  }, [aggregatedData, startDate, endDate]);

  const isDateInvalid = useMemo(() => 
    endDate.isBefore(startDate), [startDate, endDate]
  );

  const handleDateChange = useCallback((newDate, field) => {
    if (field === "start") {
      setStartDate(newDate);
    } else if (field === "end") {
      setEndDate(newDate);
    }
    setDateRangeError("");
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" align="center" sx={{ mb: 4, fontWeight: "bold" }}>
        Dashboard
      </Typography>
      <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
        <FormControl fullWidth>
          <InputLabel id="facility-select-label">Select Facility</InputLabel>
          <Select
            labelId="facility-select-label"
            id="facility-select"
            value={selectedFacility}
            label="Select Facility"
            onChange={(event) => handleFacilityChange(event.target.value)}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            renderValue={(selected) => {
              const facility = flattenedFacilities.find(f => f.id === selected);
              return facility ? (facility.level === 1 ? `${facility.parentName} - ${facility.name}` : facility.name) : '';
            }}
          >
            <VirtualizedFacilitySelect
              facilities={flattenedFacilities}
              selectedFacility={selectedFacility}
              handleFacilityChange={handleFacilityChange}
            />
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newDate) => handleDateChange(newDate, "start")}
            slotProps={{
              textField: {
                error: isDateInvalid || !!dateRangeError,
                helperText: isDateInvalid ? "Ensure Start Date is before End Date" : dateRangeError
              },
            }}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: isDateInvalid ? 'red !important' : 'inherit',
              },
              '& .MuiInputLabel-root': {
                color: isDateInvalid ? 'red !important' : 'inherit',
              },
              '& .MuiIconButton-root': {
                color: isDateInvalid ? 'red !important' : 'inherit',
              },
            }}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newDate) => handleDateChange(newDate, "end")}
            slotProps={{
              textField: {
                error: isDateInvalid || !!dateRangeError,
                helperText: isDateInvalid ? "Ensure End Date is after Start Date" : dateRangeError
              },
            }}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: isDateInvalid ? 'red !important' : 'inherit',
              },
              '& .MuiInputLabel-root': {
                color: isDateInvalid ? 'red !important' : 'inherit',
              },
              '& .MuiIconButton-root': {
                color: isDateInvalid ? 'red !important' : 'inherit',
              },
            }}
          />
        </LocalizationProvider>
      </Stack>
      {selectedFacility && !isDateInvalid && isDateRangeValid && (
        <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Typography
              variant="h6"
              align="center"
              sx={{ mb: 2, fontWeight: "bold" }}
            >
              Food Spend By Locality
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Legend data={donutData} colors={colors} />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DonutChart
                data={donutData}
                width={isSmallScreen ? 250 : 300}
                height={isSmallScreen ? 250 : 300}
                colors={colors}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Typography
              variant="h6"
              align="center"
              sx={{ mb: 2, fontWeight: "bold" }}
            >
              Food Spend Over Time
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Legend data={donutData} colors={colors} />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StackedBarChart
                data={lineData}
                width={isSmallScreen ? 250 : 400}
                height={isSmallScreen ? 200 : 300}
                colors={colors}
                valueFormatter={formatCurrency}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
      )}
    </Container>
  );
};

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export default DataDisplay;