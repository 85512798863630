import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useAuth0 } from "@auth0/auth0-react";
import logo from "../../static/logo.png";
import AuthButton from "../AuthButton";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from 'react-router-dom';

export default function Header() {
  const { isAuthenticated, user } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dataAnchorEl, setDataAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDataMenu = (event) => {
    setDataAnchorEl(event.currentTarget);
  };

  const handleDataClose = () => {
    setDataAnchorEl(null);
  };

  const handleStateSelect = (state) => {
    handleDataClose();
    navigate(`/public-data/${state.toLowerCase().replace(' ', '-')}`);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: "#ffffff",
        boxShadow: "0px 2px 4px rgba(0,0,0,0.06)",
      }}
    >
      <Toolbar>
        <img src={logo} alt="logo" style={{ marginRight: 15, height: 50 }} />
        <Typography variant="h3" style={{ flexGrow: 1, color: "black" }}>
          FoodTrails
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
          <Button
            color="inherit"
            onClick={handleDataMenu}
            endIcon={<KeyboardArrowDownIcon />}
            style={{
              color: "black",
              fontWeight: "bold",
              borderRadius: 50,
            }}
          >
            Public Data
          </Button>
          <Menu
            anchorEl={dataAnchorEl}
            open={Boolean(dataAnchorEl)}
            onClose={handleDataClose}
          >
            <MenuItem onClick={() => handleStateSelect('Rhode Island')}>
              Rhode Island
            </MenuItem>
          </Menu>
        </div>
        {isAuthenticated && user ? (
          <div style={{ display: "flex", alignItems: "center", color: "gray" }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Typography variant="body1" style={{ marginRight: 20 }}>
              {user.name}
            </Typography>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
            </Menu>
          </div>
        ) : null}
        <AuthButton />
      </Toolbar>
    </AppBar>
  );
}
