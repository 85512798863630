import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import DonutChart from './BaseComponents/DataCards/DonutChart';
import Legend from './BaseComponents/Legend';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { fetchPublicDashboardData } from '../utils/api/purchasesApi';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from './BaseComponents/GlobalErrorSnackbar';

const PublicDataDisplay = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { state } = useParams();
  const [dashboardData, setDashboardData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { handleError } = useSnackbar();
  
  const displayState = state
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  useEffect(() => {
    const loadDashboardData = async () => {
      setIsLoading(true);
      try {
        const data = await fetchPublicDashboardData();
        setDashboardData(data);
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    };

    loadDashboardData();
  }, [handleError]);

  // Transform API data for DonutChart - convert to thousands
  const donutData = dashboardData ? [
    { category: "Local", value: dashboardData.totals.local_spend / 1000 },
    { category: "Regional", value: dashboardData.totals.regional_spend / 1000 },
    { category: "Not Local", value: dashboardData.totals.not_local_spend / 1000 },
    { category: "Unknown", value: dashboardData.totals.unknown_spend / 1000 },
  ] : [];

  const colors = {
    'Local': "#1f77b4",
    'Regional': "#2ca02c",
    'Not Local': "#ff7f0e",
    'Unknown': "#d62728",
  };

  if (isLoading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        minHeight: '100vh'
      }}>
        <CircularProgress size={60} />
      </Box>
    );
  }

  if (!dashboardData) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        minHeight: '100vh'
      }}>
        <Typography variant="h6" color="error">
          Unable to load dashboard data
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ 
      padding: 3,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '1200px',
      margin: '0 auto'
    }}>
      <Typography variant="h4" align="center" sx={{ mb: 2, fontWeight: "bold" }}>
        Public Data for {displayState}
      </Typography>
      
      <Typography variant="h6" align="center" sx={{ mb: 2, color: 'text.secondary' }}>
        Showing food purchasing data for the last 12 months
      </Typography>

      <Typography variant="subtitle1" align="center" sx={{ mb: 4, color: 'text.secondary' }}>
        Based on data from {dashboardData.reporting_metrics.percentage_reporting}% of state districts
      </Typography>
        
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Typography
              variant="h6"
              align="center"
              sx={{ mb: 2, fontWeight: "bold" }}
            >
              Food Spend By Locality
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Legend data={donutData} colors={colors} />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DonutChart
                data={donutData}
                width={isSmallScreen ? 250 : 300}
                height={isSmallScreen ? 250 : 300}
                colors={colors}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PublicDataDisplay;
